import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import google from "../media/background/google.png";
import apple from "../media/background/apple.png";

import "./styles/Footer.css";

const Footer = () => {
    const { t } = useTranslation();

    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-section">
                    <h4>{t("footer.contact")}</h4>
                    <p>6 Rue Bardou 31200 Toulouse France</p>
                    <p>+33 7 52 03 72 06</p>
                </div>
                <div className="footer-section">
                    <h4>{t("footer.legal")}</h4>
                    <Link to="/privacy-policy">{t("footer.policy")}</Link>
                    <Link to="/terms">{t("footer.terms")}</Link>
                </div>
                <div className="footer-section">
                    <h4>{t("footer.download")}</h4>
                    <a href="https://play.google.com/store/apps/details?id=com.duo_date" className="home-section1-btn home-section1-google-play" target="_blank" rel="noopener noreferrer">
                        <img src={google} alt="Google Play" />
                    </a>
                    <a href="https://apps.apple.com/us/app/duo-date/id6499107057" className="home-section1-btn home-section1-app-store" target="_blank" rel="noopener noreferrer">
                        <img src={apple} alt="App Store" />
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
