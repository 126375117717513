import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { NavbarProvider } from "./NavbarContext";
import ReactGA from "react-ga4";

import Home from "./components/Home";
import Blog from "./components/Blog";
import ArticleGenericPage from "./components/ArticleGenericPage";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Terms from "./components/Terms";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

import "./App.css";

function App() {
  React.useEffect(() => {
    ReactGA.initialize([
    {
        trackingId: "G-38JC5N8RK3"
      },  
    ]);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);

  return (
    <div className="app">
      <NavbarProvider>
        <Router>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:articleId" element={<ArticleGenericPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<Terms />} />
          </Routes>
          <Footer />
        </Router>        
      </NavbarProvider>
    </div>
  );
}

export default App;
