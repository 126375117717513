import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./styles/Blog.css";

import blogBackground from "../media/background/blog.png";
import google from "../media/background/google.png";
import apple from "../media/background/apple.png";

const Blog = () => {
    const { t } = useTranslation();
    const initialFetchCount = 12;
    const displayIncrement = 6;
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 699);

    const [articles, setArticles] = useState([]);
    const [visibleCount, setVisibleCount] = useState(displayIncrement);

    useEffect(() => {
        loadArticles(0, initialFetchCount);
    }, []);

    const loadArticles = async (offset, limit) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            console.log("api:", apiUrl);
            const response = await fetch(`${apiUrl}/articles-list?limit=${limit}&offset=${offset}`);
            console.log(response);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const data = await response.json();
            setArticles(prevArticles => [...prevArticles, ...data]);
        } catch (error) {
            if (process.env.NODE_ENV !== "production") {
                console.error("Error during the GET Articles List:", error);
            }
        }
    }    

    const handleShowMore = () => {
        const newVisibleCount = visibleCount + displayIncrement;
        setVisibleCount(newVisibleCount);

        if (newVisibleCount >= articles.length) {
            loadArticles(articles.length, displayIncrement);
        }
    }

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 599);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <main className="blog">
            <div className="blog-header">
                <img src={blogBackground} className="blog-header-background" alt="Blog En-tête Background" />
                <div className="blog-header-text">
                    <h1>{!isMobile ? t("blog.desktopDescription") : t("blog.mobileDescription")}</h1>
                    <p>{t("blog.byDuoDate")}</p>
                </div>
            </div>

            <section className="blog-articles">
                {articles.slice(0, visibleCount).map(article => (
                    <div className="blog-article" key={article.id}>
                        <Link to={`/blog/${article.id}`} className="blog-article-link">
                            <div className="blog-article-image-container">
                                <img src={article.thumbnail_url} alt={article.title} loading="lazy" />
                                <div className="blog-article-date-container">
                                    <p>{article.date}</p>
                                </div>
                            </div>
                        </Link>
                        <h2 dangerouslySetInnerHTML={{ __html: article.title }}></h2>
                        <div className="blog-article-bottom">
                            <Link to={`/blog/${article.id}`}>{t("blog.seeArticle")}</Link>
                        </div>
                    </div>  
                ))}
            </section>
            
            <div className="blog-show-more-container">
                {visibleCount < articles.length && (
                    <button  className="blog-show-more" onClick={handleShowMore}>Voir plus</button>
                )}
            </div>

            <section className="home-section4">
                <div className="home-section4-content">
                    <h2>{t("home.section4Title")}</h2>
                    <p>{t("home.section4Description")}</p>
                    <div className="home-section4-buttons">
                        <a href="https://play.google.com/store" target="_blank" rel="noopener noreferrer">
                            <img src={google} alt="Google Play" className="home-section4-button" />
                        </a>
                        <a href="https://www.apple.com/app-store/" target="_blank" rel="noopener noreferrer">
                            <img src={apple} alt="App Store" className="home-section4-button" />
                        </a>
                    </div>
                </div>
            </section>

        </main>
    );
}

export default Blog;