import React from "react";
import { useTranslation } from "react-i18next";

import "./styles/Terms.css";

const Terms = () => {
    const { t } = useTranslation();

    return (
        <main className="terms">
            <section className="terms-body">
                <div className="terms-paragraph">
                    <h1>{t('terms.title')}</h1>
                    <p><strong>{t('terms.lastUpdated')}</strong> {t('terms.lastUpdatedDate')}</p>

                    <h2>{t('terms.sections.introductionTitle')}</h2>
                    <p>{t('terms.sections.introductionText')}</p>

                    <h2>{t('terms.sections.servicesTitle')}</h2>
                    <p>{t('terms.sections.servicesText')}</p>
                    <ul>
                        <li>{t('terms.sections.servicesList.suggestions')}</li>
                        <li>{t('terms.sections.servicesList.recordDates')}</li>
                        <li>{t('terms.sections.servicesList.premiumFeatures')}</li>
                    </ul>

                    <h2>{t('terms.sections.accessTitle')}</h2>
                    <p>{t('terms.sections.accessText1')}</p>
                    <p>{t('terms.sections.accessText2')}</p>

                    <h2>{t('terms.sections.contentTitle')}</h2>
                    <p>{t('terms.sections.contentText')}</p>

                    <h2>{t('terms.sections.liabilityTitle')}</h2>
                    <p>{t('terms.sections.liabilityText1')}</p>
                    <p>{t('terms.sections.liabilityText2')}</p>

                    <h2>{t('terms.sections.terminationTitle')}</h2>
                    <p>{t('terms.sections.terminationText1')}</p>
                    <p>{t('terms.sections.terminationText2')}</p>

                    <h2>{t('terms.sections.modificationsTitle')}</h2>
                    <p>{t('terms.sections.modificationsText')}</p>

                    <h2>{t('terms.sections.contactTitle')}</h2>
                    <p>{t('terms.sections.contactText')}</p>
                </div>
            </section>
        </main>
    );
}

export default Terms;
