import React, { useRef, useState, useContext, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import { NavbarContext } from "../NavbarContext";
import { useTranslation } from "react-i18next";

import "./styles/Navbar.css";

import logo from "../media/logo/logo.png";
import languageIcon from "../media/icones/language.svg";
import frenchFlagIcon from "../media/icones/french_flag.svg";
import englishFlagIcon from "../media/icones/english_flag.svg";
import spanishFlagIcon from "../media/icones/spanish_flag.svg";
import deutschFlagIcon from "../media/icones/deutsch_flag.svg";
import italianFlagIcon from "../media/icones/italian_flag.svg";
import portugueseFlagIcon from "../media/icones/portuguese_flag.svg";

const Navbar = () => {    
    const { t, i18n } = useTranslation(); 
    const menuMobileRef = useRef(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 699);
    const { navbarActive, setNavbarActive, setScrollPosition } = useContext(NavbarContext);
    const [isLanguageMenuOpen, setLanguageMenuOpen] = useState(false);
    const menuLanguageRef = useRef(null);
    const iconLanguageRef = useRef(null);

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
        setLanguageMenuOpen(false);
    };

    const resetNavbar = () => {
        setNavbarActive(false);
        setScrollPosition(0);
    }

    //Events
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 599);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return(
        <header className="navbar">
            {!isMobile ? (
                //Navbar on Desktop
                <div className="navbar-desktop">
                    <Link to="/" className="navbar-logo">
                            <img src={logo} alt="logo" className="navbar-logo-image" loading="eager" />
                    </Link>

                    <nav aria-label="desktop navigation" className="navbar-center-links">
                        <NavLink to="/" className={({ isActive }) => isActive ? "navbar-link active" : "navbar-link"}>{t('navbar.home')}</NavLink>
                        <NavLink to="/blog" className={({ isActive }) => isActive ? "navbar-link active" : "navbar-link"}>{t('navbar.blog')}</NavLink>
                    </nav>
                    
                    <div className="navbar-right-icons">
                        <div className="navbar-language-selector-container">
                            <span ref={iconLanguageRef} onClick={() => setLanguageMenuOpen(!isLanguageMenuOpen)}>
                                <img src={languageIcon} alt="Icon Changer Langue" className="navbar-language-icon" loading="eager" width="24" height="25" />
                            </span>
                            {isLanguageMenuOpen && (
                                <div ref={menuLanguageRef} className="navbar-language-selector-dropdown">
                                    <div onClick={() => changeLanguage("fr")} className="navbar-language-option">
                                        <img src={frenchFlagIcon} alt="Langue Français" className="navbar-flag-icon" loading="lazy" width="900" height="600" />Français
                                    </div>
                                    <div onClick={() => changeLanguage("en")} className="navbar-language-option">
                                        <img src={englishFlagIcon} alt="Langue Anglais" className="navbar-flag-icon" loading="lazy" width="1000" height="600" />English
                                    </div>
                                    <div onClick={() => changeLanguage("es")} className="navbar-language-option">
                                        <img src={spanishFlagIcon} alt="Langue Espagnol" className="navbar-flag-icon" loading="lazy" width="1000" height="600" />Español
                                    </div>
                                    <div onClick={() => changeLanguage("de")} className="navbar-language-option">
                                        <img src={deutschFlagIcon} alt="Langue Allemande" className="navbar-flag-icon" loading="lazy" width="1000" height="600" />Deutsch
                                    </div>
                                    <div onClick={() => changeLanguage("it")} className="navbar-language-option">
                                        <img src={italianFlagIcon} alt="Langue Italienne" className="navbar-flag-icon" loading="lazy" width="1000" height="600" />Italiano
                                    </div>
                                    <div onClick={() => changeLanguage("pt")} className="navbar-language-option">
                                        <img src={portugueseFlagIcon} alt="Langue Portuguaise" className="navbar-flag-icon" loading="lazy" width="1000" height="600" />Português
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <div className="navbar-mobile">
                    <nav aria-label="mobile navigation" className={`navbar-sidebar ${navbarActive ? "active" : ""}`} ref={menuMobileRef} >
                        <Link to="/" className="navbar-link">Accueil</Link>
                        <Link to="/blog" className="navbar-link" onClick={() => resetNavbar()}>Blog</Link>
                    </nav>
                </div>
            )}
        </header>        
    );
}

export default Navbar;