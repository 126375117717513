import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import YouTube from "react-youtube";

import './styles/Home.css';

import section1 from "../media/background/home-section1.png";
import section2 from "../media/background/home-section2.png";
import section3 from "../media/background/home-section3.png";
import google from "../media/background/google.png";
import apple from "../media/background/apple.png";
import lock from "../media/icones/lock.png";
import memory from "../media/icones/memory.png";
import heart from "../media/icones/heart.png";

const Home = () => {
    const { t } = useTranslation();
    const [isModalOpen, setModalOpen] = useState(false);

    const videoOptions = {
        height: "100%",
        width: "100%",
        playerVars: {
          autoplay: 1,
        },
    };

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    return (
        <main className="home">
            {isModalOpen && (
                <div className="video-modal-overlay" onClick={closeModal}>
                    <div className="video-modal-content" onClick={(e) => e.stopPropagation()}>
                        <YouTube videoId="ZmdNMccp0vs" opts={videoOptions} />
                    </div>
                </div>
            )}
            <section className="home-section1">
                <div className="home-section1-content">
                    <h1>
                        <span className="home-section1-highlight1">{t("home.title1")}</span>{t("home.title2")}<br />
                        <span className="home-section1-highlight2">{t("home.title3")}</span>{t("home.title4")}<br />
                        <span className="home-section1-highlight2">{t("home.title5")}</span>{t("home.title6")}
                    </h1>
                    <span className="home-section1-description">{t("home.description")}</span>
                    <p onClick={openModal} className="home-section1-link">{t("home.video")} &gt;</p>
                    <div className="home-section1-buttons">
                        <a href="https://play.google.com/store/apps/details?id=com.duo_date" className="home-section1-btn home-section1-google-play" target="_blank" rel="noopener noreferrer">
                            <img src={google} alt="Google Play" />
                        </a>
                        <a href="https://apps.apple.com/us/app/duo-date/id6499107057" className="home-section1-btn home-section1-app-store" target="_blank" rel="noopener noreferrer">
                            <img src={apple} alt="App Store" />
                        </a>
                    </div>
                </div>
                <div className="home-section1-images">
                    <img src={section1} alt="App screenshot 1" />
                </div>
            </section>

            <section className="home-section2">
                <div className="home-section2-content">
                    <div className="home-section2-image">
                        <img src={section2} alt="Phone" />
                    </div>
                    <div className="home-section2-text">
                        <h2>{t("home.section2Title")}</h2>
                        <p>{t("home.section2Description")}</p>
                        
                        <div className="home-section2-item">
                            <div className="home-section2-item-title">
                                <img src={lock} alt="Phone" />
                                <h3>{t("home.section2Item1Title")}</h3>
                            </div>
                            <p>{t("home.section2Item1")}</p>
                        </div>

                        <div className="home-section2-item">                        
                            <div className="home-section2-item-title">
                                <img src={memory} alt="Phone" />
                                <h3>{t("home.section2Item2Title")}</h3>                        
                            </div>
                            <p>{t("home.section2Item2")}</p>
                        </div>

                        <div className="home-section2-item">
                            <div className="home-section2-item-title">
                                <img src={heart} alt="Phone" />
                                <h3>{t("home.section2Item3Title")}</h3>
                            </div>
                            <p>{t("home.section2Item3")}</p>
                        </div>
                    </div>
                </div>
                <div className="home-section2-footer">
                    <h3>{t("home.section2FooterTitle")}</h3>
                    <p>{t("home.section2Footer")}</p> 
                </div>
            </section>

            <section className="home-section3">
                <div className="home-section3-content">
                    <div className="home-section3-text">
                        <h2>{t("home.section3Title")}</h2>
                        <p>{t("home.section3Description")}</p>
                    </div>
                    <div className="home-section3-images">
                        <img src={section3} alt="Phone screen 1" className="home-section3-phone" />
                    </div>
                </div>

                <div className="home-section3-content2">
                    <div className="home-section3-images reverse">
                        <img src={section3} alt="Phone screen 1" className="home-section3-phone2" />
                    </div>
                    <div className="home-section3-text2">
                        <h2>{t("home.section3Title2")}</h2>
                        <p>{t("home.section3Description2")}</p>
                    </div>
                </div>
            </section>

            <section className="home-section4">
                <div className="home-section4-content">
                    <h2>{t("home.section4Title")}</h2>
                    <p>{t("home.section4Description")}</p>
                    <div className="home-section4-buttons">
                        <a href="https://play.google.com/store/apps/details?id=com.duo_date" target="_blank" rel="noopener noreferrer">
                            <img src={google} alt="Google Play" className="home-section4-button" />
                        </a>
                        <a href="https://apps.apple.com/us/app/duo-date/id6499107057" target="_blank" rel="noopener noreferrer">
                            <img src={apple} alt="App Store" className="home-section4-button" />
                        </a>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default Home;
