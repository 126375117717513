import React from 'react';
import ReactDOM from 'react-dom/client';
import i18n from "i18next";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initReactI18next} from "react-i18next";
import enTranslation from "./locales/en/translation.json";
import frTranslation from "./locales/fr/translation.json";
import itTranslation from "./locales/it/translation.json";
import deTranslation from "./locales/de/translation.json";
import ptTranslation from "./locales/pt/translation.json";
import esTranslation from "./locales/es/translation.json";

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslation
      },
      fr: {
        translation: frTranslation
      },
      it: {
        translation: itTranslation
      },
      de: {
        translation: deTranslation
      },
      pt: {
        translation: ptTranslation
      },
      es: {
        translation: esTranslation
      }
    },
    lng: "fr",
    fallbackLng: "fr",
    interpolation: {
      escapeValue: false
    }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
