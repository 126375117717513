import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import "./styles/ArticleGenericPage.css";

const ArticleGenericPage = () => {
    const { articleId } = useParams();
    const [title, setTitle] = useState(null);
    const [content, setContent] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchArticle = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await fetch(`${apiUrl}/article/${articleId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
        
                if (!response.ok) {
                    const statusCode = response.status;
                    if (statusCode === 404) {
                        navigate("/404");
                    } else if (statusCode === 500) {
                        navigate("/500");
                    }
                    throw new Error(`HTTP error! status: ${statusCode}`);
                }
        
                const data = await response.json();

                setTitle(data.title);          
                setContent(data.content);
            } catch (error) {
                if (process.env.NODE_ENV !== "production") {
                    console.error("Error fetching article:", error);
                }
            }
        };
        

        fetchArticle();
    }, [articleId, navigate]);

    if (!title || !content) return <main className="article-page">Chargement</main>

    return (
        <main className="article-page">
            <div className="article-content">
                <article className="article-left-container">
                    <div dangerouslySetInnerHTML={{ __html: content }}></div>   
                </article>
            </div>
        </main>
    );
}

export default ArticleGenericPage;