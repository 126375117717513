import React from "react";
import { useTranslation } from "react-i18next";

import "./styles/PrivacyPolicy.css";

const PrivacyPolicy = () => {
    const { t } = useTranslation();

    return (
        <main className="privacy-policy">
            <section className="privacy-policy-body">
                <div className="privacy-policy-paragraph">
                    <h1>{t('privacyPolicy.title')}</h1>
                    <p><strong>{t('privacyPolicy.lastUpdated')}</strong> {t('privacyPolicy.lastUpdatedDate')}</p>

                    <p>{t('privacyPolicy.intro')}</p>

                    <h2>{t('privacyPolicy.sections.collectionAndUseTitle')}</h2>
                    <p>{t('privacyPolicy.sections.collectionAndUse1')}</p>
                    <p>{t('privacyPolicy.sections.collectionAndUse2')}</p>
                    <p>{t('privacyPolicy.sections.collectionAndUse3')}</p>

                    <h2>{t('privacyPolicy.sections.sharingTitle')}</h2>
                    <p>{t('privacyPolicy.sections.sharingIntro')}</p>
                    <ul>
                        <li>{t('privacyPolicy.sections.sharingConsent')}</li>
                        <li>{t('privacyPolicy.sections.sharingProviders')}</li>
                        <li>{t('privacyPolicy.sections.sharingCompliance')}</li>
                    </ul>

                    <h2>{t('privacyPolicy.sections.protectionTitle')}</h2>
                    <p>{t('privacyPolicy.sections.protection1')}</p>

                    <h2>{t('privacyPolicy.sections.choicesAndRightsTitle')}</h2>
                    <p>{t('privacyPolicy.sections.choicesAndRights1')}</p>

                    <h2>{t('privacyPolicy.sections.changesTitle')}</h2>
                    <p>{t('privacyPolicy.sections.changes1')}</p>

                    <h2>{t('privacyPolicy.sections.contactTitle')}</h2>
                    <p>{t('privacyPolicy.sections.contact1')}</p>
                </div>
            </section>
        </main>
    );
}

export default PrivacyPolicy;
